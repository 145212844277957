// src/views/admin/settings/Settings.jsx

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  Spinner,
  Switch, // Import Switch
  Flex,   // Import Flex for layout
} from "@chakra-ui/react";
import {
  AddIcon,
  DeleteIcon,
  EditIcon,
  CheckIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { useAuth } from "../../auth/AuthContext.jsx";
import { db } from "firebaseConfig.js"; // Adjust the path as necessary
import {
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs'; // Optional: For better date formatting

export default function Settings() {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const toast = useToast();

  // States for Holidays
  const [holidayDate, setHolidayDate] = useState("");
  const [holidays, setHolidays] = useState([]);
  const [isLoadingHolidays, setIsLoadingHolidays] = useState(false);

  // States for Service Time
  const [serviceTime, setServiceTime] = useState("");
  const [isEditingServiceTime, setIsEditingServiceTime] = useState(false);
  const [isLoadingServiceTime, setIsLoadingServiceTime] = useState(false);

  // States for Phone Number Authentication
  const [phoneAuthEnabled, setPhoneAuthEnabled] = useState(false);
  const [isLoadingPhoneAuth, setIsLoadingPhoneAuth] = useState(false);

  // Fetch Holidays from Firestore
  useEffect(() => {
    const fetchHolidays = async () => {
      if (!currentUser) return;

      setIsLoadingHolidays(true);
      try {
        const docRef = doc(db, "holidays", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const fetchedDates = docSnap.data().dates || [];
          setHolidays(fetchedDates);
        } else {
          setHolidays([]);
        }
      } catch (error) {
        console.error("Error fetching holidays:", error);
        toast({
          title: t("error_title"),
          description: t("error_fetching_holidays"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setIsLoadingHolidays(false);
    };

    fetchHolidays();
  }, [currentUser, t, toast]);

  // Fetch Service Time from Firestore (Global)
  useEffect(() => {
    const fetchServiceTime = async () => {
      // No need to check for currentUser since it's global
      setIsLoadingServiceTime(true);
      try {
        const docRef = doc(db, "service_time", "global"); // Changed to "global"
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setServiceTime(docSnap.data().time || "");
        } else {
          setServiceTime("");
        }
      } catch (error) {
        console.error("Error fetching service time:", error);
        toast({
          title: t("error_title"),
          description: t("error_fetching_service_time"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setIsLoadingServiceTime(false);
    };

    fetchServiceTime();
  }, [t, toast]);

  // Fetch Phone Authentication Flag from Firestore
  useEffect(() => {
    const fetchPhoneAuthFlag = async () => {
      setIsLoadingPhoneAuth(true);
      try {
        const docRef = doc(db, "phone_authentication", "config"); // Using a fixed document ID
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPhoneAuthEnabled(docSnap.data().enabled || false);
        } else {
          // Create the document with default flag as false
          await setDoc(docRef, { enabled: false });
          setPhoneAuthEnabled(false);
        }
      } catch (error) {
        console.error("Error fetching phone authentication flag:", error);
        toast({
          title: t("error_title"),
          description: t("error_fetching_phone_auth"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setIsLoadingPhoneAuth(false);
    };

    fetchPhoneAuthFlag();
  }, [t, toast]);

  // Handle Adding a Holiday with Cleanup of Past Dates
  const handleAddHoliday = async () => {
    if (!holidayDate) {
      toast({
        title: t("missing_date_title"),
        description: t("missing_date_description"),
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoadingHolidays(true);
    try {
      const docRef = doc(db, "holidays", currentUser.uid);
      const docSnap = await getDoc(docRef);

      let existingDates = [];
      if (docSnap.exists()) {
        existingDates = docSnap.data().dates || [];
      }

      const today = dayjs().format("YYYY-MM-DD"); // Using dayjs for consistency

      // Filter out past dates
      const filteredDates = existingDates.filter((date) => date >= today);

      // Update Firestore with filtered dates
      await setDoc(docRef, { dates: filteredDates }, { merge: true });
      setHolidays(filteredDates);

      // Check for duplicate
      if (filteredDates.includes(holidayDate)) {
        toast({
          title: t("duplicate_holiday_title"),
          description: t("duplicate_holiday_description"),
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        setIsLoadingHolidays(false);
        return;
      }

      // Add the new holiday
      const updatedHolidays = [...filteredDates, holidayDate];
      await setDoc(docRef, { dates: updatedHolidays }, { merge: true });
      setHolidays(updatedHolidays);
      setHolidayDate("");

      toast({
        title: t("holiday_added_title"),
        description: t("holiday_added_description"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error adding holiday:", error);
      toast({
        title: t("error_title"),
        description: t("error_adding_holiday"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoadingHolidays(false);
  };

  // Handle Deleting a Holiday
  const handleDeleteHoliday = async (date) => {
    const updatedHolidays = holidays.filter((d) => d !== date);

    try {
      const docRef = doc(db, "holidays", currentUser.uid);
      await setDoc(docRef, { dates: updatedHolidays }, { merge: true });
      setHolidays(updatedHolidays);
      toast({
        title: t("holiday_deleted_title"),
        description: t("holiday_deleted_description"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting holiday:", error);
      toast({
        title: t("error_title"),
        description: t("error_deleting_holiday"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Handle Saving Service Time (Global)
  const handleSaveServiceTime = async () => {
    if (!serviceTime || isNaN(serviceTime) || serviceTime <= 0) {
      toast({
        title: t("invalid_service_time_title"),
        description: t("invalid_service_time_description"),
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoadingServiceTime(true);
    try {
      const docRef = doc(db, "service_time", "global"); // Changed to "global"
      await setDoc(docRef, { time: Number(serviceTime) }, { merge: true });
      toast({
        title: t("service_time_saved_title"),
        description: t("service_time_saved_description"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setIsEditingServiceTime(false);
    } catch (error) {
      console.error("Error saving service time:", error);
      toast({
        title: t("error_title"),
        description: t("error_saving_service_time"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoadingServiceTime(false);
  };

  // Handle Editing Service Time
  const handleEditServiceTime = () => {
    setIsEditingServiceTime(true);
  };

  // Handle Cancel Editing Service Time
  const handleCancelEditServiceTime = () => {
    setIsEditingServiceTime(false);
    // Optionally, refetch the service time to reset the input
    const fetchServiceTime = async () => {
      setIsLoadingServiceTime(true);
      try {
        const docRef = doc(db, "service_time", "global"); // Changed to "global"
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setServiceTime(docSnap.data().time || "");
        } else {
          setServiceTime("");
        }
      } catch (error) {
        console.error("Error fetching service time:", error);
        toast({
          title: t("error_title"),
          description: t("error_fetching_service_time"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setIsLoadingServiceTime(false);
    };

    fetchServiceTime();
  };

  // Handle Toggle Switch for Phone Authentication
  const handleTogglePhoneAuth = async () => {
    const newValue = !phoneAuthEnabled;
    setIsLoadingPhoneAuth(true);
    try {
      const docRef = doc(db, "phone_authentication", "config");
      await setDoc(docRef, { enabled: newValue }, { merge: true });
      setPhoneAuthEnabled(newValue);
      toast({
        title: t("phone_auth_update_title"),
        description: newValue
          ? t("phone_auth_enabled_description")
          : t("phone_auth_disabled_description"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating phone authentication flag:", error);
      toast({
        title: t("error_title"),
        description: t("error_updating_phone_auth"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoadingPhoneAuth(false);
  };

  const cardBg = useColorModeValue("white", "gray.700");
  const cardBorder = useColorModeValue("gray.200", "gray.600");
  const labelFontSize = "sm";
  const inputFontSize = "sm";
  const buttonSize = "sm";

  return (
    <Box pt={{ base: "30px", md: "80px", xl: "80px" }}>
      <Stack spacing={6}>
        {/* Holidays Card */}
        <Box
          bg={cardBg}
          borderWidth="1px"
          borderColor={cardBorder}
          borderRadius="lg"
          p={6}
          w="100%"
        >
          <Text fontSize="xl" mb={4} fontWeight="bold" textAlign="center">
            {t("manage_holidays")}
          </Text>
          <Stack spacing={4}>
            <FormLabel fontWeight="semibold" fontSize={labelFontSize}>
              {t("add_holiday")}
            </FormLabel>
            <Grid templateColumns="1fr auto" gap={2}>
              <Input
                type="date"
                value={holidayDate}
                onChange={(e) => setHolidayDate(e.target.value)}
                fontSize={inputFontSize}
              />
              <Button
                leftIcon={<AddIcon />}
                colorScheme="teal"
                onClick={handleAddHoliday}
                size={buttonSize}
                disabled={!holidayDate || isLoadingHolidays}
                isLoading={isLoadingHolidays}
              >
                {t("add")}
              </Button>
            </Grid>

            {/* Holidays Table */}
            <Box overflowX="auto">
              {isLoadingHolidays ? (
                <Spinner size="md" />
              ) : (
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th>{t("holiday_date")}</Th>
                      <Th textAlign="center">{t("actions")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {holidays.length > 0 ? (
                      holidays.map((date) => (
                        <Tr key={date}>
                          <Td>
                            {dayjs(date).format("D MMMM YYYY")} {/* Using dayjs for better formatting */}
                          </Td>
                          <Td textAlign="center">
                            <Tooltip
                              label={t("delete_holiday")}
                              aria-label="Delete Holiday"
                            >
                              <IconButton
                                aria-label={t("delete_holiday")}
                                icon={<DeleteIcon />}
                                colorScheme="red"
                                variant="ghost"
                                size="sm"
                                onClick={() => handleDeleteHoliday(date)}
                              />
                            </Tooltip>
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr>
                        <Td colSpan={2} textAlign="center">
                          {t("no_holidays")}
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              )}
            </Box>
          </Stack>
        </Box>

        {/* Service Time Card */}
        <Box
          bg={cardBg}
          borderWidth="1px"
          borderColor={cardBorder}
          borderRadius="lg"
          p={6}
          w="100%"
        >
          <Text fontSize="xl" mb={4} fontWeight="bold" textAlign="center">
            {t("set_average_service_time")}
          </Text>
          <Stack spacing={4}>
            <FormLabel fontWeight="semibold" fontSize={labelFontSize}>
              {t("average_service_time_label")}
            </FormLabel>
            <Grid templateColumns="1fr auto" gap={2} alignItems="center">
              <Input
                type="number"
                value={serviceTime}
                onChange={(e) => setServiceTime(e.target.value)}
                placeholder={t("service_time_placeholder")}
                fontSize={inputFontSize}
                min="1"
                disabled={!isEditingServiceTime && serviceTime !== ""}
              />
              {serviceTime === "" || isEditingServiceTime ? (
                <Button
                  leftIcon={<CheckIcon />}
                  colorScheme="teal"
                  onClick={handleSaveServiceTime}
                  size={buttonSize}
                  disabled={isLoadingServiceTime}
                  isLoading={isLoadingServiceTime}
                >
                  {t("save")}
                </Button>
              ) : (
                <Tooltip
                  label={t("edit_service_time")}
                  aria-label="Edit Service Time"
                >
                  <IconButton
                    aria-label={t("edit_service_time")}
                    icon={<EditIcon />}
                    colorScheme="blue"
                    variant="ghost"
                    size="sm"
                    onClick={handleEditServiceTime}
                  />
                </Tooltip>
              )}
              {isEditingServiceTime && (
                <Tooltip
                  label={t("cancel_editing")}
                  aria-label="Cancel Editing"
                >
                  <IconButton
                    aria-label={t("cancel_editing")}
                    icon={<CloseIcon />}
                    colorScheme="red"
                    variant="ghost"
                    size="sm"
                    onClick={handleCancelEditServiceTime}
                  />
                </Tooltip>
              )}
            </Grid>
            {serviceTime !== "" && !isEditingServiceTime && (
              <Text fontSize="sm" color="gray.500">
                {t("current_service_time")}: {serviceTime} {t("minutes")}
              </Text>
            )}
          </Stack>
        </Box>

        {/* Phone Number Authentication Card */}
        <Box
          bg={cardBg}
          borderWidth="1px"
          borderColor={cardBorder}
          borderRadius="lg"
          p={6}
          w="100%"
        >
          <Text fontSize="xl" mb={4} fontWeight="bold" textAlign="center">
            {t("phone_number_authentication")}
          </Text>
          <Stack spacing={4}>
            <Flex align="center" justify="space-between">
              <FormLabel htmlFor="phone-auth-switch" fontWeight="semibold" fontSize={labelFontSize}>
                {t("enable_phone_authentication")}
              </FormLabel>
              <Switch
                id="phone-auth-switch"
                isChecked={phoneAuthEnabled}
                onChange={handleTogglePhoneAuth}
                isDisabled={isLoadingPhoneAuth}
                colorScheme="teal"
              />
            </Flex>
            {isLoadingPhoneAuth && (
              <Flex justify="center" mt={2}>
                <Spinner size="sm" />
              </Flex>
            )}
            <Text fontSize="sm" color="gray.500">
              {phoneAuthEnabled
                ? t("phone_auth_enabled_info")
                : t("phone_auth_disabled_info")}
            </Text>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
