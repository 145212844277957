// src/website/components/booking/PersonalDetails.js
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { auth, RecaptchaVerifier, signInWithPhoneNumber } from "../../../firebaseConfig";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from 'libphonenumber-js';

const PersonalDetails = ({ personalDetails, setPersonalDetails, setIsPhoneVerified, isPhoneAuthRequired, setIsPhoneValid }) => {
  const { t } = useTranslation();
  
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  
  const recaptchaVerifier = useRef(null);
  const recaptchaContainerRef = useRef(null);
  const toast = useToast();

  useEffect(() => {
    const initializeRecaptcha = () => {
      if (recaptchaContainerRef.current) {
        recaptchaContainerRef.current.innerHTML = '';
      }

      try {
        if (recaptchaVerifier.current) {
          try {
            recaptchaVerifier.current.clear();
          } catch (clearError) {
            console.warn("Error clearing previous reCAPTCHA:", clearError);
          }
        }

        recaptchaVerifier.current = new RecaptchaVerifier(
          auth,
          recaptchaContainerRef.current,
          {
            size: "invisible",
            callback: (response) => {
              console.log("reCAPTCHA solved", response);
            },
            "expired-callback": () => {
              toast({
                title: t("reCAPTCHA_expired_title") || "reCAPTCHA Expired",
                description: t("reCAPTCHA_expired_description") || "Please verify again.",
                status: "warning",
                duration: 3000,
                isClosable: true,
              });
            },
            "error-callback": (error) => {
              console.error("reCAPTCHA Error:", error);
              toast({
                title: t("verification_error_title") || "Verification Error",
                description: t("verification_error_description") || "Failed to verify. Please try again.",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            },
          }
        );
        
      } catch (error) {
        console.error("Comprehensive reCAPTCHA Initialization Error:", error);
        toast({
          title: t("setup_failed_title") || "Setup Failed",
          description: t("setup_failed_description") || "Unable to set up verification. Please refresh.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    try {
      if (recaptchaContainerRef.current) {
        initializeRecaptcha();
      }
    } catch (error) {
      console.error("Unexpected error during reCAPTCHA setup:", error);
      toast({
        title: t("unexpected_error_title") || "Unexpected Error",
        description: t("unexpected_error_description") || "An unexpected error occurred. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    return () => {
      try {
        if (recaptchaVerifier.current) {
          recaptchaVerifier.current.clear();
        }
      } catch (error) {
        console.warn("Error during reCAPTCHA cleanup:", error);
      }
    };
  }, [toast, t]);

  // **Auto-Set Phone Verified if OTP is Not Required**
  useEffect(() => {
    if (!isPhoneAuthRequired) {
      setIsPhoneVerified(true);
      setIsPhoneValid(true); // Ensure phone is considered valid
    }
  }, [isPhoneAuthRequired, setIsPhoneVerified, setIsPhoneValid]);

  // **Validate Phone Number on Change**
  const handlePhoneChange = (phone, country, e, formattedValue) => {
    // Reset verification status if the phone number changes after verification
    setIsPhoneVerified(false);
    setIsVerified(false);

    setPersonalDetails({
      ...personalDetails,
      phone: `+${phone}`,
    });

    // Validate phone number
    if (phone && isValidPhoneNumber(`+${phone}`)) {
      setPhoneError("");
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  };

  const sendOTP = async () => {
    if (!personalDetails.phone || !isValidPhoneNumber(personalDetails.phone)) {
      toast({
        title: t("invalid_phone_number_title") || "Invalid Phone Number",
        description: t("invalid_phone_number_description") || "Please enter a valid phone number.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    try {
      if (recaptchaVerifier.current) {
        await recaptchaVerifier.current.verify();
      }

      const result = await signInWithPhoneNumber(
        auth, 
        personalDetails.phone, 
        recaptchaVerifier.current
      );

      setConfirmationResult(result);
      toast({
        title: t("otp_sent_title") || "OTP Sent",
        description: t("otp_sent_description") || "Verification code has been sent to your phone.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("OTP Send Error:", error);
      
      let errorMessage = t("otp_send_failed_default") || "Failed to send OTP. Please try again.";
      
      if (error.code === 'auth/invalid-phone-number') {
        errorMessage = t("otp_send_failed_invalid_phone") || "Invalid phone number. Please check and try again.";
      } else if (error.code === 'auth/quota-exceeded') {
        errorMessage = t("otp_send_failed_quota") || "SMS quota exceeded. Please try again later.";
      } else if (error.code === 'auth/internal-error') {
        errorMessage = t("otp_send_failed_internal") || "Internal verification error. Please check your configuration.";
      }

      toast({
        title: t("otp_send_failed_title") || "OTP Send Failed",
        description: errorMessage,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOTP = async () => {
    if (!otp || otp.length < 6) {
      toast({
        title: t("invalid_otp_title") || "Invalid OTP",
        description: t("invalid_otp_description") || "Please enter a valid verification code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    try {
      if (!confirmationResult) {
        throw new Error("No confirmation result. Please send OTP first.");
      }

      await confirmationResult.confirm(otp);
      
      toast({
        title: t("verification_successful_title") || "Verification Successful",
        description: t("verification_successful_description") || "Your phone number has been verified.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setIsPhoneVerified(true);
      setIsVerified(true);

    } catch (error) {
      console.error("OTP Verification Error:", error);
      
      let errorMessage = t("otp_verification_failed_default") || "Invalid verification code. Please try again.";
      
      if (error.code === 'auth/invalid-verification-code') {
        errorMessage = t("otp_verification_failed_invalid_code") || "The verification code is invalid. Please check and retry.";
      } else if (error.code === 'auth/code-expired') {
        errorMessage = t("otp_verification_failed_expired_code") || "Verification code has expired. Please request a new one.";
      }

      toast({
        title: t("verification_failed_title") || "Verification Failed",
        description: errorMessage,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Text fontSize="xl" mb={4}>
        {t("enter_personal_details")}
      </Text>
      <VStack spacing={6} align="stretch">
        <FormControl isRequired isInvalid={!!phoneError}>
          <FormLabel>{t("name")}</FormLabel>
          <Input
            placeholder={t("your_name")}
            value={personalDetails.name}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                name: e.target.value,
              })
            }
          />
        </FormControl>

        <FormControl isRequired isInvalid={!!phoneError}>
          <FormLabel>{t("phone_number")}</FormLabel>
          <PhoneInput
            country={"ch"}
            value={personalDetails.phone}
            onChange={handlePhoneChange}
            inputStyle={{
              width: "100%",
              height: "40px",
              paddingLeft: "48px",
              borderRadius: "4px",
              borderColor: phoneError ? "red.500" : "#E2E8F0",
            }}
            buttonStyle={{
              borderColor: phoneError ? "red.500" : "#E2E8F0",
            }}
            containerStyle={{ width: "100%" }}
            disableSearch={false}
            placeholder={t("enter_phone_number")}
          />
          {phoneError && (
            <FormErrorMessage>{phoneError}</FormErrorMessage>
          )}
        </FormControl>
      </VStack>

      {/* **Conditional Rendering of OTP Section** */}
      {isPhoneAuthRequired && (
        <>
          <FormControl>
            <Box 
              ref={recaptchaContainerRef} 
              id="recaptcha-container"
              width="100%"
              display="none"
            />
          </FormControl>

          <Box mt={8} px={4}>
            <VStack spacing={6} align="center">
              {!confirmationResult && !isVerified && (
                <Button 
                  colorScheme="blue" 
                  onClick={sendOTP}
                  isLoading={isLoading}
                  width="100%"
                  maxW="400px"
                  height="45px"
                  borderRadius="8px"
                >
                  {t("send_otp")}
                </Button>
              )}
              {confirmationResult && !isVerified && (
                <VStack spacing={4} width="100%" maxW="400px">
                  <FormControl isRequired>
                    <FormLabel fontSize="sm">
                      {t("enter_verification_code")}
                    </FormLabel>
                    <Input
                      placeholder=""
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      maxLength={6}
                      textAlign="center"
                      fontSize="lg"
                      height="45px"
                      borderRadius="8px"
                    />
                  </FormControl>
                  <Button 
                    colorScheme="green" 
                    onClick={verifyOTP}
                    isLoading={isLoading}
                    width="100%"
                    height="45px"
                    borderRadius="8px"
                  >
                    {t("verify_otp")}
                  </Button>
                </VStack>
              )}
            </VStack>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PersonalDetails;
